/**
 * Copyright © Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

/**
 * @api
 */
define('Magento_Ui/js/modal/modal-mixin',[
    'jquery',
    'underscore',
    'mage/template',
    'jquery-ui-modules/widget',
    'mage/translate'
], function ($, _) {
    'use strict';

    /**
     * Detect browser transition end event.
     * @return {String|undefined} - transition event.
     */
    var transitionEvent = (function () {
        var transition,
            elementStyle = document.createElement('div').style,
            transitions = {
                'transition': 'transitionend',
                'OTransition': 'oTransitionEnd',
                'MozTransition': 'transitionend',
                'WebkitTransition': 'webkitTransitionEnd'
            };

        for (transition in transitions) {
            if (elementStyle[transition] !== undefined && transitions.hasOwnProperty(transition)) {
                return transitions[transition];
            }
        }
    })();

    return function (widget) {
        $.widget('mage.modal', widget, {
            /**
             * Creates modal widget.
             */
            _create: function () {
                _.bindAll(
                    this,
                    'keyEventSwitcher',
                    '_tabSwitcher',
                    'closeModal'
                );

                this.options.id = this.uuid;
                this.options.transitionEvent = transitionEvent;
                this._createWrapper();
                this._renderModal();
                this._createButtons();
                $(this.options.trigger).on('click', _.bind(this.toggleModal, this));
                this._on(this.modal.find(this.options.modalCloseBtn), {
                    'click': this.options.modalCloseBtnHandler ? this.options.modalCloseBtnHandler : this.closeModal
                });
                this._on(this.element, {
                    'openModal': this.openModal,
                    'closeModal': this.closeModal
                });
                this.options.autoOpen ? this.openModal() : false;
            },

            /**
             * Set title for modal.
             *
             * @param {String} title
             */
            setTitle: function (title) {
                var $title = $(this.options.modalTitle),
                    $subTitle = this.modal.find(this.options.modalSubTitle);

                $title.text(title);
                $title.append($subTitle);
            },

            /**
             * Creates buttons pane.
             */
            _createButtons: function () {
                this.buttons = this._getElem(this.options.modalAction);
                _.each(this.options.buttons, function (btn, key) {
                    var button = this.buttons[key];

                    if (btn.attr) {
                        $(button).attr(btn.attr);
                    }

                    if (btn.class) {
                        $(button).addClass(btn.class);
                    }

                    if (!btn.click) {
                        btn.click = this.closeModal;
                    }
                    $(button).on('click', _.bind(btn.click, this));

                    button.textContent = $.mage.__(button.textContent);

                }, this);
            }
        });

        return $.mage.modal;
    }
});

